/* eslint-disable no-console */
import locale from 'antd/lib/date-picker/locale/pt_BR';
import moment from 'moment';
import {
  DatePicker,
  Form,
  Input,
  Modal,
  Button,
  message,
  Row,
  Progress,
  Col,
  Spin,
  Empty,
} from 'antd';
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { DeleteDialog } from '~/components';
import * as fetchTowers from '~/store/fetchActions/fetchTowers';
import { percentageToColor } from '~/helpers/scheduler';
import './styles.scss';

moment.locale('pt-BR');

export default function CustomAllocationForm({
  allocationVisible,
  setAllocationVisible,
  record,
  allocation,
  reload,
}) {
  const [values, setValues] = useState({
    personKey: record.personKey,
    amount: allocation?.amount || 100,
    startDate: allocation?.startDate || moment(),
    endDate: allocation?.endDate || moment(),
  });
  const [isSplit, setIsSplit] = useState(false);
  const [
    isLoadingProjectAllocations,
    setIsLoadingProjectAllocations,
  ] = useState(false);
  const [soluterProjectsAllocations, setSoluterProjectsAllocations] = useState(
    [],
  );

  const [form] = Form.useForm();

  const eventExists = allocation?.startDate;

  useEffect(() => {
    if (!allocation || !allocationVisible) return;
    setValues(prevState => {
      return {
        ...prevState,
        startDate: allocation.startDate || record.startDate,
        endDate: allocation.endDate || record.endDate,
        personKey: record?.personKey,
        amount: allocation.amount || 100,
      };
    });
  }, [allocation, record, allocationVisible]);

  useEffect(() => {
    form.setFieldsValue({
      personName: record?.person,
      amount: values.amount,
      startDate: moment(values.startDate),
      endDate: moment(values.endDate),
      splitDate: '',
    });
    fetchTowers.getAllocated(record?.personKey);
  }, [values, form, record?.person, record?.personKey]);

  const getAllocated = useCallback(async () => {
    setIsLoadingProjectAllocations(true);
    const getAllocatedSolluters = await fetchTowers.getAllocated(
      record?.personKey,
    );
    setSoluterProjectsAllocations(getAllocatedSolluters);
    setIsLoadingProjectAllocations(false);
  }, [record?.personKey]);

  useEffect(() => {
    getAllocated();
  }, [getAllocated, record]);

  async function handleSubmit(formValues) {
    const dateValidation = isInvalidDate(
      formValues.startDate,
      formValues.endDate,
    );
    if (dateValidation) {
      message.warn(dateValidation);
      return;
    }

    const formStartDate = formValues.startDate.format('YYYY-MM-DD');
    const formEndDate = formValues.endDate.format('YYYY-MM-DD');

    if (!allocation.startDate) {
      await addAllocation(formValues, formStartDate, formEndDate);
    } else {
      await editAllocation(formValues, formStartDate, formEndDate);
    }

    setAllocationVisible(false);
    setIsSplit(false);
    reload();
  }

  async function handleSplit(formValues) {
    const dateValidationStart = isInvalidDate(
      formValues.startDate,
      formValues.splitDate,
    );
    const dateValidationEnd = isInvalidDate(
      formValues.splitDate,
      formValues.endDate,
    );
    if (dateValidationStart) {
      message.warn(dateValidationStart);
      return;
    }
    if (dateValidationEnd) {
      message.warn(dateValidationEnd);
      return;
    }

    const formStartDate = formValues.startDate.format('YYYY-MM-DD');
    const formSplitDate = formValues.splitDate.format('YYYY-MM-DD');
    const formEndDate = formValues.endDate.format('YYYY-MM-DD');
    delete formValues.splitDate;

    await editAllocation(formValues, formStartDate, formSplitDate);
    delete formValues.amount;
    await addAllocation(
      { ...formValues, amount: formValues.amount_second },
      formSplitDate,
      formEndDate,
    );

    form.setFieldsValue({ amount_second: '' });
    setAllocationVisible(false);
    setIsSplit(false);
    reload();
  }

  async function editAllocation(formValues, startDate, endDate) {
    try {
      await fetchTowers.updateAllocation({
        ...allocation,
        ...formValues,
        projectId: record.projectId,
        teamMemberId: record.id || record._id,
        id: allocation.id,
        startDate,
        endDate,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function addAllocation(formValues, startDate, endDate) {
    try {
      await fetchTowers.addAllocation({
        ...formValues,
        startDate,
        endDate,
        projectId: record.projectId,
        teamMemberId: record.id || record._id,
      });
    } catch (error) {
      console.log(error);
    }
  }

  function isInvalidDate(startDate, endDate) {
    const projectStartDate = moment(record.startDate).startOf('day');
    const projectEndDate = moment(record.endDate).startOf('day');
    const allocationStartDate = startDate.startOf('day');
    const allocationEndDate = endDate.startOf('day');

    if (allocationStartDate.isAfter(allocationEndDate)) {
      return 'A data de início de uma alocação não pode ser maior que a data de fim da alocação';
    }

    if (allocationStartDate.isBefore(projectStartDate)) {
      return 'A alocação não pode começar antes da data de início do projeto';
    }

    if (allocationEndDate.isAfter(projectEndDate)) {
      return 'A alocação não pode terminar depois da data de fim do projeto';
    }

    return false;
  }

  const handleCancel = () => {
    setAllocationVisible(false);
    setIsSplit(false);
    form.resetFields();
  };

  const handleOk = () => {
    form
      .validateFields()
      .then(formValues =>
        isSplit ? handleSplit(formValues) : handleSubmit(formValues),
      )
      .catch(console.error);
  };

  return (
    <Modal
      title={
        eventExists ? 'Editar Alocação no Projeto' : 'Alocar Pessoa ao Projeto'
      }
      visible={allocationVisible}
      onCancel={() => handleCancel()}
      footer={
        <div
          style={{
            display: 'flex',
            justifyContent: eventExists ? 'space-between' : 'flex-end',
          }}
        >
          {eventExists && (
            <DeleteDialog
              handleDelete={async () => {
                /* eventStore.remove(eventRecord.eventRecord); */
                try {
                  await fetchTowers.removeAllocation({
                    ...allocation,
                    projectId: record.projectId,
                    id: allocation.id,
                  });
                } catch (error) {
                  console.log(error);
                }
                setAllocationVisible(false);
                setIsSplit(false);
                reload();
              }}
              item={{ title: 'esta alocação', id: '' }}
              subText=""
              content={
                <Button type="primary" danger>
                  Deletar
                </Button>
              }
            />
          )}
          <div style={{ justifySelf: 'flex-end' }}>
            <Button key="back" onClick={() => handleCancel()}>
              Cancelar
            </Button>
            <Button type="primary" onClick={() => handleOk()}>
              Salvar
            </Button>
          </div>
        </div>
      }
    >
      <Form form={form} layout="vertical">
        <Form.Item name="personName" label="Pessoa">
          <Input disabled />
        </Form.Item>
        {!isSplit && (
          <Form.Item
            name="amount"
            rules={[
              {
                pattern: /^([0-9]|[1-9][0-9]|100)\b$/,
                required: true,
                message:
                  'O percentual de alocação deve ser um número entre 0 e 100',
              },
            ]}
            label="Percentual de Alocação (0-100)"
          >
            <Input />
          </Form.Item>
        )}
        {allocation.startDate && (
          <Row justify="center">
            <Button
              data-testid="split-allocation-button"
              type={isSplit ? 'primary' : ''}
              onClick={() => {
                setIsSplit(!isSplit);
              }}
            >
              Dividir alocação?
            </Button>
          </Row>
        )}
        {!isSplit || !allocation.startDate ? (
          <Row
            justify={eventExists ? 'center' : 'flex-start'}
            style={{ marginTop: '15px' }}
          >
            <Form.Item name="startDate" label="Início da  Alocação">
              <DatePicker
                locale={locale}
                format="DD/MM/YYYY"
                style={{ marginRight: 20 }}
                allowClear={false}
                inputReadOnly
              />
            </Form.Item>
            <Form.Item name="endDate" label="Fim da Alocação">
              <DatePicker
                locale={locale}
                format="DD/MM/YYYY"
                allowClear={false}
                inputReadOnly
              />
            </Form.Item>
          </Row>
        ) : (
          <>
            <Row style={{ marginTop: '15px' }} justify="space-between">
              <Form.Item name="startDate" label="Início da 1ª Alocação">
                <DatePicker
                  locale={locale}
                  format="DD/MM/YYYY"
                  allowClear={false}
                  inputReadOnly
                />
              </Form.Item>
              <Form.Item />
              <Form.Item
                name="amount"
                style={{ width: '40%' }}
                rules={[
                  {
                    pattern: /^([0-9]|[1-9][0-9]|100)\b$/,
                    required: true,
                    message:
                      'O percentual de alocação deve ser um número entre 0 e 100',
                  },
                ]}
                label="Percentual da 1ª Alocação"
              >
                <Input />
              </Form.Item>
            </Row>
            <Row>
              <Form.Item
                name="splitDate"
                label="Data de divisão"
                rules={[
                  {
                    required: true,
                    message: 'Obrigatório em caso de divisão.',
                  },
                ]}
              >
                <DatePicker
                  locale={locale}
                  format="DD/MM/YYYY"
                  allowClear={false}
                  inputReadOnly
                />
              </Form.Item>
            </Row>
            <Row justify="space-between">
              <Form.Item name="endDate" label="Fim da 2ª Alocação">
                <DatePicker
                  locale={locale}
                  format="DD/MM/YYYY"
                  allowClear={false}
                  inputReadOnly
                />
              </Form.Item>
              <Form.Item
                name="amount_second"
                style={{ width: '40%' }}
                rules={[
                  {
                    pattern: /^([0-9]|[1-9][0-9]|100)\b$/,
                    required: true,
                    message:
                      'O percentual de alocação deve ser um número entre 0 e 100',
                  },
                ]}
                label="Percentual da 2ª Alocação"
              >
                <Input />
              </Form.Item>
            </Row>
          </>
        )}
        {/* {!eventExists ? (
          <>
            <h3>Alocações do Soluter</h3>
            <Progress percent={50} />
          </>
        ) : (
          <h3>edicao</h3>
        )} */}
        <h3>Alocações do Soluter</h3>

        <Spin spinning={isLoadingProjectAllocations}>
          {soluterProjectsAllocations.length > 0 ? (
            soluterProjectsAllocations?.map((projectAllocation, index) => {
              return (
                <>
                  <Row style={{ marginTop: 23 }}>
                    <h4 style={{ marginRight: 5 }}>Projeto: </h4>
                    <div style={{ marginRight: 20 }}>
                      {projectAllocation.project}
                    </div>
                    <h4 style={{ marginRight: 5 }}>Torre: </h4>
                    <div>{projectAllocation.group}</div>
                  </Row>
                  <Row>
                    <div style={{ marginRight: 5 }}>
                      {moment(projectAllocation.startDate).format('DD/MM/YYYY')}{' '}
                      -
                    </div>
                    <div style={{ marginRight: 20 }}>
                      {moment(projectAllocation.endDate).format('DD/MM/YYYY')}
                    </div>
                    <p
                      className="allocation-amount"
                      style={{
                        background: percentageToColor(
                          projectAllocation.allocation === undefined
                            ? 0
                            : projectAllocation.allocation,
                        ),
                        marginBottom: 4,
                        color: 'black',
                      }}
                    >
                      {`${
                        projectAllocation.allocation === undefined
                          ? '0'
                          : projectAllocation.allocation
                      }%`}
                    </p>
                  </Row>
                </>
              );
            })
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Não há alocações"
            />
          )}
        </Spin>
      </Form>
    </Modal>
  );
}

CustomAllocationForm.propTypes = {
  allocationVisible: PropTypes.bool.isRequired,
  setAllocationVisible: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
  allocation: PropTypes.object,
  reload: PropTypes.func.isRequired,
};

CustomAllocationForm.defaultProps = {
  allocation: {},
};
